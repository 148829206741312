<template>
  <master-layout>
    <div class="mb-3 text-right">
      <v-btn
        class="ml-3 add-btn"
        dark
        @click="addOrUpdateActivity('add')"
        :disabled="!accessEnableAdd"
      >
        <v-icon>mdi-plus</v-icon> Add Activity
      </v-btn>
    </div>
    <v-card>
      <v-row class="ma-0">
        <v-col cols="12" sm="8">
          <v-card-title class="pa-0">
            Activity List
            <!-- <v-spacer></v-spacer> -->
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            max-width="400px"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        item-key="id"
        :headers="registerHeader"
        :items="registrationList"
        class="elevation-1 mytable"
        :loading="loading"
        must-sort
        :search="search"
      >
        <template v-slot:[`header.name`]="{ header }">
          {{ header.text.toUpperCase() }}
        </template>

        <template v-slot:[`item.phoneNumber`]="{ item }">
          {{
            item.phoneNumber
              .replace(/\D+/g, "")
              .replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, "$1-$2-$3")
          }}
        </template>

        <template v-slot:[`item.isActive`]="{ item }">
          <v-icon style="color: #f0ce6a" v-if="item.isActive"
            >mdi-check-bold</v-icon
          >
          <v-icon style="color: #f0ce6a" v-else>mdi-close-thick</v-icon>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                text
                :disabled="!accessEnableUpdate"
                v-bind="attrs"
                v-on="on"
                @click="addOrUpdateActivity('update', item)"
              >
                <v-icon color="primary">mdi-pencil</v-icon></v-btn
              >
            </template>
            Edit Activity
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <!-- <progress-bar :show="loading" />
    <v-simple-table fixed-header height="600px" class="d-none d-sm-block">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">Activity Name</th>
            <th class="text-center">Created On</th>
           
            <th class="text-center">Active</th>

            <th class="text-center">Actions</th>
          </tr>
        </thead>

        <tbody>
          <tr align="center" v-for="item in ActivityDetail" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.addedOn }}</td>
           
            <td>
              <v-icon style="color: #f0ce6a" v-if="item.isActive"
                >mdi-check-bold</v-icon
              >
              <v-icon style="color: #f0ce6a" v-else>mdi-close-thick</v-icon>
            </td>

            <td>
              <v-btn
                text
                class="edit-pancil"
                @click="addOrUpdateActivity('update', item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->
    <!-- mobile view -->
    <!-- <v-card
      class="d-sm-none mobile_card mb-3"
      elevation="1"
      outlined
      v-for="item in ActivityDetail"
      :key="item.id"
    >
      <div class="d-flex align-center card-head">
        <div>
          <strong>{{ item.name }}</strong>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="pr-0 m-edit"
          @click="addOrUpdateActivity('update', item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <div class="d-flex pt-3 crad-body-content">
        <div>
          <ul class="list">
            <li class="list-weblink">
              <label>Active On</label>
              <span>{{ item.addedOn }}</span>
            </li>
            <li class="list-address-info">
              <label>Update On</label>
              <span>{{ item.updatedOn }}</span>
            </li>

            <li class="list-active-info">
              <label>Active</label>
              <span>
                <v-icon style="color: #f0ce6a" v-if="item.isActive"
                  >mdi-check-bold</v-icon
                >
                <v-icon style="color: #f0ce6a" v-else>mdi-close-thick</v-icon>
              </span>
            </li>
            <li class="list-created-info"></li>
          </ul>
        </div>
      </div>
    </v-card> -->

    <ActivityModal
      :dialog="dialog"
      :dataReceived="updateActivityValue"
      @reloadActivity="getActivityList()"
      @disableDialog="dialog = $event"
    />
  </master-layout>
</template>
<script>
import ActivityService from "../services/ActivityService.js";
import ActivityModal from "../components/Modals/ActivityModal.vue";
import { actionEnable } from "../utils/helpers";

export default {
  components: {
    ActivityModal,
  },
  name: "ActivityManagement",
  computed: {
    accessEnableAdd() {
      return actionEnable("Activity", "Add", 1);
    },
    accessEnableUpdate() {
      return actionEnable("Activity", "Update", 1);
    },
  },
  data: () => ({
    name: "",
    search: "",
    ActivityDetail: [],
    dialog: false,
    updateActivityValue: {},
    loading: false,
    registerHeader: [
      { text: "Activity Name", value: "name", width: 150 },
      { text: "Created On", value: "addedOn", width: 150 },
      { text: "Active", value: "isActive", width: 100, sortable: false },
      { text: "Actions", value: "action", width: 100, sortable: false },
    ],
    registrationList: [],
  }),
  async mounted() {
    this.getActivityList();
  },
  methods: {
    async getActivityList() {
      try {
        this.loading = true;
        const response = await ActivityService.activityList();
        this.registrationList = response.data.data;
        this.ActivityDetail = response.data.data;
        if (response.data.errorMessage) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }

        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unauthorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },
    async getActivityDetails() {
      try {
        const { data } = await ActivityService.getActivityDetail({});
        this.ActivityDetail = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    addOrUpdateActivity(value, item) {
      if (value == "add") {
        this.dialog = true;
        this.updateActivityValue = { title: "Add New Activity" };
      } else {
        this.dialog = true;
        this.updateActivityValue = { ...item, title: "Update Activity" };
      }
    },
  },
};
</script>
