import http from "./http-client";

class ActivityService {
  getActivityDetail(payload) {
    return http.get("/Activity/Details", payload);
  }
  updateActivity(payload) {
    return http.put("/Activity/Update", payload);
  }
  activityList(payload) {
    if (!payload) {
      return http.get(`/Activity/List`);
    } else {
      return http.get(`/Activity/List?isActive=${payload}`);
    }
  }

  addActivity(payload) {
    return http.post("/Activity/Add", payload);
  }
}

export default new ActivityService();
